<template>
  <list-content
      v-bind:rows-data="tableData"
      v-bind:columns="tableColumns"
      v-bind:add-label="pageContent.add_label"
      v-bind:title="pageContent.title"
      v-bind:new-uri="pageContent.newUri"
      v-bind:edit-uri="pageContent.editUri"
      v-bind:meta="meta"
      v-bind:show-message="showMessage"
      v-bind:message="message"
      @handlePageChange="handleCurrentChange"
      @handleSizeChange="handleSizeChange"
      @handleSearchData="performSearch"
      @handleDeleteData="handleDelete"
  ></list-content>
</template>

<script>
import CommunityService from "@/api/community.service";
import ListContent from "@/components/ListContent";

export default {
  name: "Blogs",
  components: {ListContent},
  data() {
    return {
      tableColumns: [
          {'key':'name', 'label':'Community  Name', 'sortable':true, 'is_image': false, 'width':''},
          {'key':'status', 'label':'Status', 'sortable':true, 'is_image': false, 'width':102},
          {'key':'created_at', 'label':'Added On', 'sortable':true, 'is_image': false, 'width':140}
      ],
      pageContent: {
        'add_label': 'Add Community',
        'title': 'Communities',
        'newUri': '/community/add',        
        'editUri':'/community/edit'
      },
      tableData: [],
      page: 1,
      search: '',
      meta: Object,
      showMessage: false,
      message: {message:'', messageType:'success', effect:'dark'}
    }
  },
  mounted() {
    this.getTableData();
  },
  methods: {
    async getTableData() {
      return CommunityService.community(this.page, this.search).then(response => {
        this.meta = response.data.meta;
        this.tableData = response.data.data;
      });
    },
    performSearch(search) {
      this.search = search.name;
      this.getTableData();
    },
    handleDelete(data) {
      let deleteRow = data.row;
      this.showMessage = false;
      if (data.indexNo >= 0) {
        return CommunityService.delete(deleteRow).then(response => {
          if(response.status === 201) {
            this.message.message = "Blog Article deleted successfully";
            this.message.messageType = "success";
            this.message.effect = "dark";
            this.showMessage = true;
            this.getTableData();
          }
        });
      }
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getTableData();
    },
    handleSizeChange(val){
      this.getTableData();
    }
  }
}
</script>
<style lang="scss">
</style>
<style lang="scss" scoped>
</style>
